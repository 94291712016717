import axios from "./_base";
import errorHandler from "./_errorHandler";

export const getAllStatistics = (vue, callback) => {
    axios.get('http://192.168.43.96/api/all_statistics', {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error)
    });
};
