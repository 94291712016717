<template>
  <div v-if="loaded">
    <CRow>

      <CCol md="3" sm="6">
        <div class="card bg-white border-radius-5">
          <div class="card-header content-center text-white p-0 bg-facebook">
            <div class="font-lg">{{ $t('number_of_users') }}</div>
          </div>
          <div class="card-body row text-center">
            <div class="col">
              <div class="text-value-lg">
                {{ totalUsers }}
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('user') }}
              </div>
            </div>
          </div>
        </div>
      </CCol>

      <CCol md="3" sm="6">
        <div class="card bg-white border-radius-5">
          <div class="card-header content-center text-white p-0 bg-gray-600 text-white">
            <div class="font-lg">{{ $t('number_of_customers') }}</div>
          </div>
          <div class="card-body row text-center">
            <div class="col">
              <div class="text-value-lg">
                {{ totalCustomers }}
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('customer') }}
              </div>
            </div>
          </div>
        </div>
      </CCol>

      <CCol md="3" sm="6">
        <div class="card bg-white border-radius-5">
          <div class="card-header content-center text-white p-0 bg-facebook">
            <div class="font-lg">{{ $t('number_of_products') }}</div>
          </div>
          <div class="card-body row text-center">
            <div class="col">
              <div class="text-value-lg">
                {{ totalProducts }}
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('a_product') }}
              </div>
            </div>
          </div>
        </div>
      </CCol>

      <CCol md="3" sm="6">
        <div class="card bg-white border-radius-5">
          <div class="card-header content-center text-white p-0 bg-gray-600 text-white">
            <div class="font-lg">{{ $t('number_of_invoices') }}</div>
          </div>
          <div class="card-body row text-center">
            <div class="col">
              <div class="text-value-lg">
                {{ totalInvoices }}
              </div>
              <div class="text-uppercase text-muted small">
                {{ $t('invoice') }}
              </div>
            </div>
          </div>
        </div>
      </CCol>

    </CRow>
  </div>
</template>

<script>
import {getAllStatistics} from '../../api/statistics'

export default {
  name: 'UserHome',
  data() {
    return {
      loaded: false,
      totalCustomers: 0,
      totalUsers: 0,
      totalProducts: 0,
      totalInvoices: 0,
    }
  },
  mounted() {
    console.log(this.$store.state.user.role);
    if (!this._.isEmpty(this.$store.state.user.gas_stations) && this.$store.state.user.role !== 'system_admin') {
      this.$router.push({name: 'new_worker_invoice'});
    } else {
      this.setAllStatistics();
    }
  },
  methods: {
    setAllStatistics() {
      getAllStatistics(this, (response) => {
        this.totalCustomers = response.total_customers;
        this.totalUsers = response.total_users;
        this.totalProducts = response.total_products;
        this.totalInvoices = response.total_invoices;

        this.loaded = true;
      });
    }
  },
}
</script>
